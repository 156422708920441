import instance from '@/api'
import { organizationsApiUrl } from '.'

export default {
  /**
   * Get a list of invitations.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  getInvitations(organizationID) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/invitations')
  },

  /**
   * Invite a user.
   * @param {Number} organizationID The organization ID.
   * @param {Object} data `role`, `email`
   * @returns {Promise<AxiosResponse>}
   */
  createInvitation(organizationID, data) {
    return instance.post(organizationsApiUrl + '/' + organizationID + '/invitations', data)
  },

  /**
   * Get details of a user invitation.
   * @param {Number} organizationID The organization ID.
   * @param {String} email The user's email.
   * @returns {Promise<AxiosResponse>}
   */
  getInvitation(organizationID, email) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/invitations/' + email)
  },

  /**
   * Modification of a user invitation
   * @param {Number} organizationID The organization ID.
   * @param {String} email The user's email.
   * @param {Object} data `role`, `email`
   * @returns {Promise<AxiosResponse>}
   */
  editInvitation(organizationID, email, data) {
    return instance.put(organizationsApiUrl + '/' + organizationID + '/invitations/' + email, data)
  },

  /**
   * Delete a user invitation.
   * @param {Number} organizationID The organization ID.
   * @param {String} email The user's email.
   * @returns {Promise<AxiosResponse>}
   */
  deleteInvitation(organizationID, email) {
    return instance.delete(organizationsApiUrl + '/' + organizationID + '/invitations/' + email)
  },

  /**
   * Accept an invitation.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  acceptInvitation(organizationID) {
    return instance.post(organizationsApiUrl + '/' + organizationID + '/invitations/accept')
  },

  /**
   * Decline an invitation.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  declineInvitation(organizationID) {
    return instance.post(organizationsApiUrl + '/' + organizationID + '/invitations/decline')
  }
}
