import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import EventBus from '@/common/eventBus'
import { makeTitle } from '@/common/meta'
import { useAccountStore } from '@/store/account'
import { useAppStore } from '@/store/app.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/welcome',
      name: 'Onboarding',
      component: () => import('../views/account/OnboardingView.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/account/LoginView.vue'),
      meta: {
        hideSidebar: true
      }
    },
    {
      path: '/authorize/:provider',
      name: 'Authorize',
      component: () => import('../views/account/AuthorizeView.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('../views/account/RegisterView.vue'),
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/verifyAccount',
      name: 'VerifyAccount',
      component: () => import('../views/account/VerifyAccountView.vue'),
      meta: {
        title: 'Verify Account'
      }
    },
    {
      path: '/verifyEmail',
      name: 'VerifyEmail',
      component: () => import('../views/account/VerifyEmailView.vue'),
      meta: {
        title: 'Verify Email'
      }
    },
    {
      path: '/resetPassword',
      name: 'ResetPassword',
      component: () => import('../views/account/ResetPasswordView.vue'),
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/changePassword',
      name: 'ChangePassword',
      component: () => import('../views/account/ChangePasswordView.vue'),
      meta: {
        title: 'Change Password'
      }
    },
    {
      path: '/account',
      component: () => import('../views/account/AccountView.vue'),
      children: [
        {
          path: '/account/',
          name: 'Account',
          component: () => import('../views/account/ProfileView.vue'),
          meta: {
            title: 'Profile',
            sidebarVisible: true
          }
        },
        {
          path: 'billing',
          name: 'Billing',
          component: () => import('../views/account/BillingView.vue'),
          meta: {
            title: 'Billing',
            sidebarVisible: true
          }
        },
        {
          path: 'integrations/:organizationID',
          name: 'Integrations',
          props: (route) => ({
            organizationID: Number(route.params.organizationID)
          }),
          component: () => import('../views/account/Integrations.vue'),
          meta: {
            title: 'Cloud Accounts',
            sidebarVisible: true
          }
        },
        {
          path: 'notifications',
          name: 'Notifications',
          component: () => import('../views/account/Notifications.vue'),
          meta: {
            title: 'Notifications',
            sidebarVisible: true
          }
        },
        {
          path: 'organizations/:organizationID',
          name: 'OrganizationsTeams',
          props: (route) => ({
            organizationID: Number(route.params.organizationID)
          }),
          component: () => import('../views/account/OrganizationsTeams.vue'),
          meta: {
            title: 'Organizations & Teams',
            sidebarVisible: true
          }
        },
        {
          path: 'invitations',
          name: 'AccountInvitations',
          component: () => import('../views/account/AccountInvitations.vue'),
          meta: {
            title: 'Invitations',
            sidebarVisible: true
          }
        }
      ]
    },
    {
      path: '/organizations/:organizationID',
      props: (route) => ({
        organizationID: Number(route.params.organizationID)
      }),
      component: () => import('../views/DashboardView.vue'),
      children: [
        {
          path: 'invitation',
          name: 'OrganizationInvitation',
          component: () => import('../views/OrganizationInvitationView.vue'),
          meta: {
            title: 'Organization invitation',
            sidebarVisible: true
          }
        },
        {
          path: '',
          name: 'Overview',
          component: () => import('../views/dashboard/OverviewView.vue'),
          meta: {
            title: 'Home',
            sidebarVisible: true
          }
        },
        {
          path: 'cost',
          name: 'CostDashboard',
          component: () => import('../views/dashboard/CostDashboardView.vue'),
          meta: {
            title: 'Cost Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/diagrams',
          name: 'InventoryDiagramView',
          component: () => import('../views/inventory/InventoryDiagramView.vue'),
          meta: {
            title: 'Inventory Diagram View',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/diagram/:provider',
          props: true,
          name: 'InventoryProviderDiagramView',
          component: () => import('../views/inventory/InventoryProviderDiagramView.vue'),
          meta: {
            title: 'Provider Diagram',
            sidebarVisible: false
          }
        },
        {
          path: 'inventory/config',
          props: true,
          name: 'ResourceConfigInventory',
          component: () => import('../views/inventory/ResourceConfigInventoryView.vue'),
          meta: {
            title: 'Resource config inventory',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/virtual-tags',
          name: 'InventoryVirtualTags',
          component: () => import('../views/inventory/VirtualTagsView.vue'),
          meta: {
            title: 'Virtual Tags',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory',
          name: 'InventoryDashboard',
          component: () => import('../views/inventory/InventoryView.vue'),
          meta: {
            title: 'Inventory Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/:provider/service/:service',
          name: 'InventoryService',
          component: () => import('../views/inventory/InventoryServiceView.vue'),
          props: true,
          meta: {
            title: '$service - Inventory Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/:provider/:service/:providerID/:resourceID',
          name: 'InventoryResourceDetailFromByResource',
          component: () => import('../views/inventory/InventoryResourceDetailView.vue'),
          props: (route) => ({
            provider: route.params.provider,
            service: route.params.service,
            providerID: Number(route.params.providerID),
            resourceID: route.params.resourceID
          }),
          meta: {
            title: '$service / $resourceID - Inventory Resource Detail',
            sidebarVisible: true
          }
        },
        {
          path: 'inventory/:providerID/resource/:resourceID',
          name: 'InventoryResourceDetail',
          component: () => import('../views/inventory/InventoryResourceDetailView.vue'),
          props: (route) => ({
            providerID: Number(route.params.providerID),
            resourceID: route.params.resourceID
          }),
          meta: {
            title: '$resourceID - Inventory Resource Detail',
            sidebarVisible: true
          }
        },
        {
          path: 'compliance',
          name: 'InventoryCompliance',
          component: () => import('../views/inventory/ComplianceView.vue'),
          meta: {
            title: 'Compliance Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'recommendations/rightsizing/:provider/:service',
          name: 'RecommendationsRightsizingService',
          component: () => import('../views/recommendations/category/RightsizingService.vue'),
          props: ({ params: { provider, service }}) => ({
            category: 'rightsizing',
            provider,
            service
          }),
          meta: {
            title: 'Recommendations Service',
            sidebarVisible: true
          }
        },
        {
          path: 'recommendations/commitment/:provider',
          name: 'RecommendationsCommitmentProvider',
          component: () => import('../views/recommendations/category/CommitmentProvider.vue'),
          props: true,
          meta: {
            title: 'Recommendations Commitment',
            sidebarVisible: true
          }
        },
        {
          path: 'recommendations/unused/:provider/:service?',
          name: 'RecommendationsUnusedService',
          component: () => import('../views/recommendations/category/UnusedService.vue'),
          props: true,
          meta: {
            title: 'Recommendations Unused',
            sidebarVisible: true
          }
        },
        {
          path: 'recommendations',
          name: 'RecommendationsOverview',
          component: () => import('../views/recommendations/RecommendationsOverviewView.vue'),
          meta: {
            title: 'Recommendations Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'recommendations/category/:category?',
          name: 'RecommendationsCategory',
          component: () => import('../views/recommendations/RecommendationsCategoryView.vue'),
          props: true,
          meta: {
            title: 'Recommendations Category',
            sidebarVisible: true
          }
        },
        {
          path: 'business-metrics',
          name: 'BusinessMetrics',
          component: () => import('../views/dashboard/BusinessMetricsView.vue'),
          meta: {
            title: 'Business Metrics',
            sidebarVisible: true
          }
        },
        {
          path: 'budget',
          name: 'BudgetDashboard',
          component: () => import('../views/dashboard/BudgetView.vue'),
          meta: {
            title: 'Budget Dashboard',
            sidebarVisible: true
          }
        },
        {
          path: 'cloud-accounts',
          name: 'CloudAccounts',
          component: () => import('../views/account/Integrations.vue'),
          meta: {
            title: 'Cloud Accounts',
            sidebarVisible: true
          },
          props: {
            displaySearch: true
          }
        },
        {
          path: 'projects/new/:type?/:template?',
          name: 'NewProject',
          component: () => import('../views/project/NewProjectView.vue'),
          props: true,
          meta: {
            title: 'New Project',
            sidebarVisible: true
          }
        },
        {
          path: 'project/compare/:left/:right',
          name: 'ProjectCompare',
          props: (route) => ({
            left: Number(route.params.left),
            right: Number(route.params.right)
          }),
          component: () => import('../views/project/ProjectCompareView.vue'),
          meta: {
            title: 'Compare projects',
            hideSidebar: true
          }
        },
        {
          path: 'project/diff/:left/:right',
          name: 'ProjectDiff',
          props: (route) => ({
            left: Number(route.params.left),
            right: Number(route.params.right)
          }),
          component: () => import('../views/project/ProjectDiffView.vue'),
          meta: {
            title: 'Diff projects',
            hideSidebar: true
          }
        },
        {
          path: 'project/:id',
          component: () => import('../views/project/ProjectView.vue'),
          props: (route) => ({
            id: Number(route.params.id)
          }),
          children: [
            {
              path: '',
              name: 'ProjectDesign',
              component: () => import('../components/diagram/Diagram.vue'),
              meta: {
                autoTitle: true
              }
            },
            {
              path: 'estimate/:provider?',
              name: 'ProjectEstimate',
              component: () => import('../components/diagram/Estimate.vue'),
              props: true,
              meta: {
                title: 'Estimate',
                hideSidebar: true
              }
            },
            {
              path: 'export',
              name: 'ProjectExport',
              component: () => import('../components/diagram/export/ExportDocumentation.vue'),
              props: true,
              meta: {
                title: 'Export',
                hideSidebar: true
              }
            }
          ]
        },
        {
          path: 'projects',
          name: 'ProjectsOverview',
          component: () => import('../views/project/ProjectsView.vue'),
          meta: {
            title: 'Projects',
            sidebarVisible: true
          }
        },
        {
          path: 'provider/:providerID',
          component: () => import('../views/provider-account/ProviderAccountView.vue'),
          props: (route) => ({
            providerID:
              route.params.providerID === 'demo' ? 'demo' : Number(route.params.providerID)
          }),
          meta: {
            sidebarVisible: true
          },
          children: [
            {
              path: '',
              name: 'ProviderAccount',
              component: () => import('../views/provider-account/DetailsView.vue')
            },
            {
              path: 'visualize',
              name: 'ProviderAccountVisualize',
              component: () => import('../views/provider-account/ProviderAccountVisualizeView.vue'),
              meta: {
                sidebarVisible: false
              }
            },
            {
              path: 'compare',
              name: 'ProviderAccountCompare',
              component: () => import('../views/provider-account/ProviderAccountCompareView.vue'),
              meta: {
                sidebarVisible: false
              }
            },
            {
              path: 'diff',
              name: 'ProviderAccountDiff',
              component: () => import('../views/provider-account/ProviderAccountDiffView.vue'),
              meta: {
                sidebarVisible: false
              }
            },
            {
              path: 'export',
              name: 'ProviderAccountExport',
              component: () => import('../views/provider-account/ProviderAccountExport.vue'),
              meta: {
                sidebarVisible: false
              }
            }
          ]
        }
      ]
    },
    {
      path: '/pricing',
      name: 'Pricing',
      meta: {
        title: 'Pricing'
      },
      component: () => import('../views/PricingPlansView.vue')
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('../views/account/LogoutView.vue')
    },
    {
      path: '/not-found',
      name: 'NotFound',
      meta: {
        title: 'Oops!'
      },
      component: () => import('../views/NotFoundView.vue')
    },
    { path: '/:pathMatch(.*)*', redirect: '/not-found' } // catch any unknown routes
  ]
})

const loggedInRoutes = [
  'Home',
  'Account',
  'Integrations',
  'Notifications',
  'OrganizationsTeams',
  'Onboarding',
  'Overview',
  'OrganizationInvitation',
  'CostDashboard',
  'InventoryDashboard',
  'InventoryDiagramView',
  'Projects',
  'Project',
  'ProjectDesign',
  'ProjectEstimate',
  'ProjectExport',
  'Dashboard',
  'NewProject',
  'ProviderAccount',
  'ProviderAccountVisualize',
  'ProviderAccountCompare',
  'ProviderAccountDiff',
  'NewProviderAccount',
  'Team',
  'NewTeam',
  'VerifyEmail',
  'UpgradePricingPlan',
  'Checkout',
  'CheckoutResult',
  'ProjectDiffVisualize',
  'ProjectDiffCompare',
  'ProjectDiffDiff'
]

export function abortAllRequests() {
  window.cancelledRequests = []
  const { abortController } = window

  if (abortController) {
    abortController.abort()

    window.cancelledRequests = [...window.launchedRequests]
  }

  window.abortController = new AbortController()

  // Clear the launched requests
  window.launchedRequests = []
}

router.beforeEach(async (to, from, next) => {
  const appStore = useAppStore()
  appStore.resetLoading()

  if (to.path !== from.path) {
    EventBus.dispatch('setPageTitle', '')
  }

  abortAllRequests()

  const accountStore = useAccountStore()
  let isAuthenticated = accountStore.isLoggedIn
  const logInRequired = loggedInRoutes.includes(to.name)

  if (!logInRequired) {
    return next()
  }

  if (isAuthenticated && (to.name === 'Login' || to.name === 'ConnectSSO')) {
    return next({
      name: 'Overview',
      query: from.query
    })
  }

  if (to.name === 'Authorize') {
    // SSO uses a cloud provider auth page to get authorization code
    // then once back to Authorize page, we get a token and
    // let redirect to the destination hold in the authorization state
    return next()
  } else if (logInRequired && !isAuthenticated) {
    next({
      name: to.fullPath.endsWith('invitation') ? 'Register' : 'Login',
      query: {
        ...to.query,
        // remember from where we logged out in the url
        redirect:
          to.fullPath !== '/' && loggedInRoutes.includes(to.name) ? to.fullPath.substring(1) : ''
      }
    })
  } else {
    // all is in order, continue
    next()
  }
})

router.afterEach((to) => {
  const { autoTitle, title } = to.meta
  if (!autoTitle) {
    nextTick(() => {
      let finalTitle = title || 'Home'
      const vars = finalTitle.match(/\$([A-Za-z]+)/g)
      if (Array.isArray(vars)) {
        for (const v of vars) {
          const varName = v.replace('$', '')
          finalTitle = finalTitle.replace(v, to.params[varName])
        }
      }
      document.title = makeTitle(finalTitle)
    })
  }
})

export default router
