import invitationsApi from '@/api/organizations/invitations'
import { defineStore } from 'pinia'
import { useOrganizationStore } from '@/store/organization.js'
import { useAccountStore } from '@/store/account.js'

export const useInvitationStore = defineStore({
  id: 'invitation',

  state: () => ({
    invitation: null,
    invitations: [],
    userInvitations: []
  }),

  getters: {
    getUserInvitationsCount: ({ userInvitations }) => userInvitations.length
  },

  actions: {
    /**
     * Get a list of invitations.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    getInvitations(organizationID) {
      return new Promise((resolve) => {
        invitationsApi.getInvitations(organizationID).then(({ data }) => {
          this.invitations = data
          resolve(data)
        })
      })
    },

    getUserInvitations(details = false) {
      const organizationStore = useOrganizationStore()

      const currentOrganizationID = organizationStore.getCurrentOrganization?.id || undefined
      const invitedOrganizations = organizationStore.organizations.filter(({ id }) => id !== currentOrganizationID)
      this.userInvitations = []

      if (!details) {
        this.userInvitations = invitedOrganizations
      } else {
        const accountStore = useAccountStore()

        return new Promise((bigResolve) => {
          const promises = invitedOrganizations.map((organization) =>
            new Promise((resolve) =>
              this.getInvitation(organization.id, accountStore.account.email)
                .then((invitation) => resolve({
                  ...invitation,
                  name: organization.name
                }))
            )
          )

          Promise.allSettled(promises).then((results) => {
            const invitations = results.map(({ value }) => value)
            this.userInvitations = invitations
            bigResolve(invitations)
          })
        })
      }
    },

    /**
     * Invite a user.
     * @param {Number} organizationID The organization ID.
     * @param {String} role The role.
     * @param {String} email The email.
     * @returns {Promise<AxiosResponse>}
     */
    createInvitation(organizationID, role, email) {
      return invitationsApi.createInvitation(organizationID, { role, email })
    },

    /**
     * Get details of a user invitation.
     * @param {Number} organizationID The organization ID.
     * @param {String} email The user's email.
     * @returns {Promise<AxiosResponse>}
     */
    getInvitation(organizationID, email) {
      return new Promise((resolve, reject) => {
        invitationsApi.getInvitation(organizationID, email).then(
          ({ data }) => {
            this.invitation = data
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Modification of a user invitation
     * @param {Number} organizationID The organization ID.
     * @param {String} email The user's email.
     * @param {Object} data `role`, `email`
     * @returns {Promise<AxiosResponse>}
     */
    editInvitation(organizationID, email, data) {
      return invitationsApi.editInvitation(organizationID, email, data)
    },

    /**
     * Delete a user invitation.
     * @param {Number} organizationID The organization ID.
     * @param {String} email The user's email.
     * @returns {Promise<AxiosResponse>}
     */
    deleteInvitation(organizationID, email) {
      return invitationsApi.deleteInvitation(organizationID, email)
    },

    /**
     * Accept an invitation.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    acceptInvitation(organizationID) {
      return invitationsApi.acceptInvitation(organizationID)
    },

    /**
     * Decline an invitation.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    declineInvitation(organizationID) {
      return invitationsApi.declineInvitation(organizationID)
    }
  }
})
