<script setup>
import { ref, watch, onBeforeUnmount, onMounted } from 'vue'
import EventBus from '@/common/eventBus'
import SyncingAccountModal from '@/components/modal/SyncingAccount.vue'
import CalendlyModal from '@/components/modal/Calendly.vue'
import { useRoute, useRouter, RouterView } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useMappingStore } from '@/store/mapping'
import { useProviderAccountsStore } from '@/store/provider-accounts'
import SideMenu from '@/components/SideMenu.vue'
import { OLoading, OModal } from '@oruga-ui/oruga-next'
import AppLoading from '@/components/AppLoading.vue'
import { useInvitationStore } from '@/store/invitation.js'

const route = useRoute()
const router = useRouter()

const isPageLoading = ref(false)
const calendlyOpen = ref(false)
const sidebarVisible = ref(
  route.meta.sidebarVisible !== undefined ? route.meta.sidebarVisible : false
)

const accountStore = useAccountStore()
const providerAccountsStore = useProviderAccountsStore()
const mappingStore = useMappingStore()
const invitationStore = useInvitationStore()

watch(
  () => route.meta.sidebarVisible,
  (newVal) => {
    sidebarVisible.value = newVal

    if (newVal) {
      invitationStore.getUserInvitations()
    }
  }
)

const { syncing } = storeToRefs(providerAccountsStore)

onBeforeUnmount(() => {
  EventBus.remove('logout')
  EventBus.remove('openCalendly', openCalendly)
})

onMounted(() => {
  EventBus.on('startGlobalLoading', () => {
    isPageLoading.value = true
  })
  EventBus.on('stopGlobalLoading', () => {
    isPageLoading.value = false
  })
  EventBus.on('logout', (query) => {
    logout(query)
  })
})

const getProvidersData = mappingStore.getProvidersData
const getPricingPlans = mappingStore.getPricingPlans
const setSyncing = providerAccountsStore.setSyncing

function logout(query = {}) {
  accountStore
    .logout()
    .catch(() => {})
    .finally(() => {
      router
        .push({
          path: '/',
          query
        })
        .catch(() => {})
    })
}

function openCalendly() {
  calendlyOpen.value = true
}

getProvidersData()
getPricingPlans()

EventBus.on('openCalendly', openCalendly)
</script>

<template>
  <main>
    <o-modal v-model:active="calendlyOpen" @close="calendlyOpen = false">
      <CalendlyModal v-if="calendlyOpen" @close="calendlyOpen = false" />
    </o-modal>
    <o-modal :active="!!syncing" @close="setSyncing(null)">
      <SyncingAccountModal v-if="!!syncing" @close="setSyncing(null)" />
    </o-modal>
    <o-loading :active="isPageLoading" :full-page="true" />
    <AppLoading />
    <div class="is-flex is-min-height-100vh">
      <Transition name="fade-x-reverse">
        <SideMenu v-if="sidebarVisible" />
      </Transition>
      <RouterView v-slot="{ Component }">
        <component
          :is="Component"
          class="is-flex-grow-1"
          :class="{ 'sidebar-margin': sidebarVisible }"
        />
      </RouterView>
    </div>
  </main>
</template>
